import { useEffect } from 'react';
import { useRouter } from 'next/router';

const ScrollTo = ({ targetId }) => {
  const router = useRouter();

  const scrollToElement = () => {
    const elem = document.getElementById(targetId);
    if (!elem) return;

    const currentScrollPosition = window.pageYOffset;
    const targetPosition =
      elem.getBoundingClientRect().top + currentScrollPosition;

    window.scrollTo({
      top: targetPosition - 130,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (router.asPath.includes(targetId)) {
      // Wait for the browser to finish with the current frame and call our function before the next repaint
      window.requestAnimationFrame(scrollToElement);
    }
  }, [router.asPath]);

  return null;
};

export default ScrollTo;
