import styles from "@/components/ConnectContainer.module.css";
import { useState } from "react";
//import { handleAnalytics } from "@/pages/api/analyticsHandler";

const ConnectContainer = () => {
  const [email, setEmail] = useState({
    email: "",
    subscriptionResponse: "",
    error: false,
    isSending: false,
    emailValid: false,
    tag: "Newsletter",
  });

  const validateEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const handleSubmit = async () => {
    /*
    // Validate the email format
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailPattern.test(email.email)) {
      setEmail({
        ...email,
        subscriptionResponse: "Invalid email format",
        error: true,
      });
      return;
    }
    */

    if (email.error) {
      return;
    }

    setEmail({ ...email, error: false, isSending: true });

    try {
      const signup = await fetch("/api/newsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email_address: email.email,
          status: "subscribed",
          tag: email.tag,
        }),
      });
      const response = await signup.json();

      // Track form submission
      // trackForm("#newsletter-form", "Submitted Newsletter Form", "/");
      /*
      await handleAnalytics(
        "formSubmission",
        "#newsletter-form",
        "Submitted Newsletter Form",
        "/"
      );
      */
      //console.log("API Response:", response); // Add this line to log the response

      setEmail({
        ...email,
        subscriptionResponse: response.message,
        error: response.errorCode && response.errorCode === 400,
        isSending: false,
      });
    } catch (error) {
      // Track form submission error
      //trackError("Form Submission Error", "Newsletter", error.message);
      /*
      await handleAnalytics(
        "formSubmissionError",
        "Form Submission Error",
        "Newsletter",
        error.message
      );
      */

      setEmail({
        ...email,
        subscriptionResponse: error.message || "Something went wrong 😔",
        error: true,
        isSending: false,
      });
    }
  };

  return (
    <div className={styles.newsletter}>
      <div className={styles.sectionContent}>
        <div className={styles.sectionIntroduction}>
          <div className={styles.sectionSubtitle}>Stay connected</div>
          <div className={styles.sectionDescription}>
            Subscribe to our newsletter for the latest news and updates on
            ClearWatt's mission. We promise not to spam you.
          </div>
        </div>

        <form
          id="newsletter-form"
          className={styles.newsletterSubscription}
          onSubmit={(e) => {
            e.preventDefault();
            // Call the handleSubmit function
            handleSubmit();
          }}>
          <div className={styles.inputControl}>
            <input
              type="email"
              value={email.email}
              onChange={(e) => {
                const isValid = validateEmail(e.target.value);
                setEmail({
                  ...email,
                  email: e.target.value,
                  error: !isValid && e.target.value !== "",
                  subscriptionResponse:
                    e.target.value === ""
                      ? ""
                      : isValid
                      ? ""
                      : "Invalid email format 🚨",
                  emailValid: isValid,
                });
              }}
              id="email_nl"
              name="email_nl"
              placeholder="What’s your email?"
              className={styles.input_nl}
              required
            />

            <button
              type={"submit"}
              className={styles.buttonSubscribe}
              disabled={!email.email || email.error}>
              Subscribe
            </button>
          </div>
          <div
            className={email.error ? styles.stateError : styles.stateSuccess}>
            {email.isSending
              ? "Sending...⏳"
              : email.subscriptionResponse
              ? email.subscriptionResponse
              : email.emailValid && email.email
              ? "Email looks good ✅"
              : ""}
          </div>
        </form>
      </div>
      <div className={styles.lineContent}>
        <div className={styles.line} />
      </div>
    </div>
  );
};

export default ConnectContainer;
