import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import styles from '@/components/forms/v2/BetaWizardForm.module.css'
import MySelect from '@/components/ui/selects/MySelect'
import data from '@/data/countries.json'
import { FaArrowLeft } from 'react-icons/fa'
import { FaArrowRight } from 'react-icons/fa'
import Select from 'react-select'

const steps = ['Personal Information', 'Mobile Platform', 'Vehicle Details']

export default function BetaWizardForm({ audienceType }) {
  const router = useRouter()
  const [source, setSource] = useState(null)

  const [currentStep, setCurrentStep] = useState(0)
  const [countries, setCountries] = useState([])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [showMessage, setShowMessage] = useState(false)

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    evMake: '',
    evModel: '',
    evModelVariant: '',
    evYear: '',
    subscriptionResponse: '',
    error: false,
    status: 'subscribed',
    mobilePlatform: '',
    phoneNumber: '',
    TAGS: ['Beta Tester'],
    country: 'GB',
    motivation: '',
  })

  useEffect(() => {
    if (!router.isReady) return

    const sourceFromQuery = router.query.source || null
    setSource(sourceFromQuery)

    if (sourceFromQuery) {
      setFormData({ ...formData, TAGS: ['Beta Tester', sourceFromQuery] })
    }
  }, [router.query, router.isReady])

  useEffect(() => {
    setCountries(data)
  }, [])

  useEffect(() => {
    if (currentStep === 2 && isSubmitting) {
      handleSubmit()
      updateIsSubmitting(false) // Reset after submitting
    }
  }, [formData, currentStep, isSubmitting])

  const goForward = () => setCurrentStep((prevStep) => prevStep + 1)
  const goBackward = () => setCurrentStep((prevStep) => prevStep - 1)

  const updateFormData = (newData) => {
    setFormData({ ...formData, ...newData })
  }

  const updateIsSubmitting = (value) => {
    setIsSubmitting(value)
  }

  const handleSubmit = async () => {
    if (formData.error) {
      return
    }

    setFormData({ ...formData, error: false })
    setIsLoading(true)

    try {
      const signup = await fetch('/api/betaAlt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email_address: formData.email,
          mobilePlatform: formData.mobilePlatform,
          evMake: formData.evMake,
          evModel: formData.evModel + ' ' + formData.evModelVariant,
          evYear: formData.evYear,
          status: formData.status,
          TAGS: formData.TAGS,
          phoneNumber: formData.phoneNumber,
          country: formData.country,
          audienceType,
          motivation: formData.motivation,
        }),
      })

      const response = await signup.json()
      setFormData({
        ...formData,
        subscriptionResponse: response.message,
        error: response.errorCode && response.errorCode === 400,
      })
    } catch (error) {
      setFormData({
        ...formData,
        subscriptionResponse: error.message || 'Sorry, something went wrong 😔; please reload the page and try again.',
        error: true,
      })
    } finally {
      setIsLoading(false)
      setShowMessage(true)
    }
  }

  return (
    <div className={styles.wizard}>
      {isLoading ? (
        <div className={styles.message}>
          <div className={styles.messageContent}>
            <div className={styles.loadingMessage}>Loading...</div>
          </div>
        </div>
      ) : (
        showMessage && (
          <div className={styles.message}>
            <div className={styles.messageContent}>
              <div className={formData.error ? styles.errorMessage : styles.successMessage}>
                <div dangerouslySetInnerHTML={{ __html: formData.subscriptionResponse }}></div>
              </div>
            </div>
          </div>
        )
      )}
      <div className={styles.stepsContainer}>
        {steps.map((step, index) => (
          <div className={`${styles.step} ${currentStep >= index ? styles.active : ''}`} key={index}>
            {step}
          </div>
        ))}
      </div>

      {currentStep === 0 && (
        <Step1 goForward={goForward} updateFormData={updateFormData} formData={formData} countries={countries} />
      )}
      {currentStep === 1 && (
        <Step2 goForward={goForward} goBackward={goBackward} updateFormData={updateFormData} formData={formData} />
      )}
      {currentStep === 2 && (
        <Step3
          goBackward={goBackward}
          updateFormData={updateFormData}
          formData={formData}
          updateIsSubmitting={updateIsSubmitting}
          audienceType={audienceType}
        />
      )}
    </div>
  )
}

// STEP 1
function Step1({ goForward, updateFormData, formData, countries }) {
  const [values, setValues] = useState({
    firstName: formData.firstName || '',
    lastName: formData.lastName || '',
    email: formData.email || '',
    phoneNumber: formData.phoneNumber || '',
    country: formData.country || 'GB',
    motivation: formData.motivation ? formData.motivation.split(', ') : [],
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }))
  }

  const handleMotivationChange = (selectedOptions) => {
    const newValue = selectedOptions ? selectedOptions.map((option) => option.value) : []
    setValues((prevValues) => ({
      ...prevValues,
      motivation: newValue,
    }))
  }

  const validateAndProceed = () => {
    const newErrors = {}

    const fieldDisplayNames = {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phoneNumber: 'Phone Number',
      country: 'Country',
      motivation: 'Reason for signing up',
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailPattern.test(values.email)) {
      newErrors.email = 'Invalid email format'
    }

    const phonePattern = /^(\+\d{1,4})?\d{7,13}$/
    if (!phonePattern.test(values.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number'
    }

    // Check for mandatory fields
    const mandatoryFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'country', 'motivation']
    for (const field of mandatoryFields) {
      if (!values[field] || (Array.isArray(values[field]) && values[field].length === 0)) {
        newErrors[field] = `${fieldDisplayNames[field]} is mandatory`
      }
    }

    // If no errors, go forward, otherwise set the errors state
    if (Object.keys(newErrors).length === 0) {
      const concatenatedMotivation = values.motivation.join(', ')
      updateFormData({ ...values, motivation: concatenatedMotivation })
      goForward()
    } else {
      setErrors(newErrors)
    }
  }

  const options = [
    { value: 'works_in_industry', label: 'I work in the industry' },
    { value: 'unhappy_with_range', label: "I recently got an EV and I'm not happy with the range" },
    { value: 'is_curious', label: "I'm curious" },
    { value: 'business_reasons', label: "I'm interested for business reasons" },
    { value: 'recommended_to_them', label: 'Someone recommended this app to me' },
  ]

  return (
    <div className={styles.stepContent}>
      <div className={styles.formControl}>
        <label>First name *</label>
        <input type="text" name="firstName" value={values.firstName} onChange={handleChange} placeholder="First Name" />
        {errors.firstName && <div className={styles.errorMessage}>{errors.firstName}</div>}
      </div>
      <div className={styles.formControl}>
        <label>Last name *</label>
        <input type="text" name="lastName" value={values.lastName} onChange={handleChange} placeholder="Last Name" />
        {errors.lastName && <div className={styles.errorMessage}>{errors.lastName}</div>}
      </div>
      <div className={styles.formControl}>
        <label>Email *</label>
        <input
          type="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          placeholder="Email"
          className={`${errors.email ? styles.error : ''}`}
        />
        {errors.email && <div className={styles.errorMessage}>{errors.email}</div>}
      </div>
      <div className={styles.formControl}>
        <label>Phone number *</label>
        <input
          type="tel"
          name="phoneNumber"
          value={values.phoneNumber}
          onChange={handleChange}
          placeholder="Phone Number"
          className={`${errors.phoneNumber ? styles.error : ''}`}
        />
        {errors.phoneNumber && <div className={styles.errorMessage}>{errors.phoneNumber}</div>}
      </div>
      <div className={styles.formControl}>
        <label>Country *</label>
        <MySelect
          name="country"
          value={values.country}
          onChange={handleChange}
          placeholder="Select a country"
          hasSearch={true}
        >
          {countries.map((country) => (
            <option key={country.code} value={country.code} searchKey={country.name}>
              {country.flag} {country.name}
            </option>
          ))}
        </MySelect>
      </div>
      <div className={styles.formControl}>
        <label>Reason for signing up *</label>
        <Select
          isMulti
          name="motivation"
          options={options}
          classNamePrefix="select"
          value={options.filter((option) => values.motivation.includes(option.value))}
          onChange={handleMotivationChange}
          placeholder="Select all that apply"
        />
        {errors.motivation && <div className={styles.errorMessage}>{errors.motivation}</div>}
      </div>
      <div className={styles.actions}>
        <div></div>
        <button className={styles.action} onClick={validateAndProceed}>
          <div className={styles.label}>Next</div>
          <FaArrowRight className={styles.icon} size={24} />
        </button>
      </div>
    </div>
  )
}

// STEP 2
function Step2({ goForward, goBackward, updateFormData, formData }) {
  const [values, setValues] = useState({
    mobilePlatform: formData.mobilePlatform || '',
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const validateAndProceed = () => {
    const newErrors = {}

    // Check for mandatory fields
    const mandatoryFields = ['mobilePlatform']
    for (const field of mandatoryFields) {
      if (!values[field]) {
        newErrors[field] = `${field} is mandatory`
      }
    }

    // If no errors, go forward, otherwise set the errors state
    if (Object.keys(newErrors).length === 0) {
      updateFormData(values)
      goForward()
    } else {
      setErrors(newErrors)
    }
  }

  const goBack = () => {
    updateFormData(values)
    goBackward()
  }

  return (
    <div className={styles.stepContent}>
      <div className={styles.formControl}>
        <label>Mobile platform *</label>

        <MySelect
          name="mobilePlatform"
          value={values.mobilePlatform}
          onChange={handleChange}
          placeholder="Select platform"
          hasSearch={false}
        >
          <option value="iPhone">iPhone</option>
          <option value="Android">Android</option>
        </MySelect>
      </div>
      <div className={styles.actions}>
        <button className={styles.action} onClick={goBack}>
          <FaArrowLeft className={styles.icon} size={24} />
          <div className={styles.label}>Back</div>
        </button>
        <button className={styles.action} onClick={validateAndProceed}>
          <div className={styles.label}>Next</div>
          <FaArrowRight className={styles.icon} size={24} />
        </button>
      </div>
    </div>
  )
}

// STEP 3
function Step3({ goBackward, updateFormData, formData, updateIsSubmitting, audienceType }) {
  const [values, setValues] = useState({
    evMake: formData.evMake || '',
    evModel: formData.evModel || '',
    evModelVariant: formData.evModelVariant || '',
    evYear: formData.evYear || '',
  })

  // Add a new state variable for the checkbox
  const [shareDataWithMotability, setShareDataWithMotability] = useState(false)

  const [years, setYears] = useState([])

  useEffect(() => {
    const years = Array.from({ length: 13 }, (_, i) => 2024 - i)

    setYears(years)
  }, [])

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    if (e.target.name === 'shareDataWithMotability') {
      setShareDataWithMotability(e.target.checked)
    } else {
      setValues({ ...values, [e.target.name]: e.target.value })
    }
  }

  const validateAndProceed = () => {
    const newErrors = {}

    // Check for mandatory fields
    const mandatoryFields = ['evMake', 'evModel', 'evYear']
    for (const field of mandatoryFields) {
      if (!values[field]) {
        newErrors[field] = `${field} is mandatory`
      }
    }

    // Check if the checkbox is checked only if audienceType is "mo"
    if (audienceType === 'mo' && !shareDataWithMotability) {
      newErrors.shareDataWithMotability = 'You must agree to share data with Motability'
    }

    if (Object.keys(newErrors).length === 0) {
      updateFormData(values)
      updateIsSubmitting(true)
    } else {
      setErrors(newErrors)
    }
  }

  const goBack = () => {
    // No need to go forward anymore, but save the data
    updateFormData(values)
    goBackward()
    updateIsSubmitting(false)
  }

  return (
    <div className={styles.stepContent}>
      <div className={styles.formControl}>
        <label>Make *</label>
        <input type="text" name="evMake" value={values.evMake} onChange={handleChange} placeholder="Make of the EV" />
        {errors.evMake && <div className={styles.errorMessage}>{errors.evMake}</div>}
      </div>
      <div className={styles.formControl}>
        <label>Model *</label>
        <input
          type="text"
          name="evModel"
          value={values.evModel}
          onChange={handleChange}
          placeholder="Model of the EV"
        />
        {errors.evModel && <div className={styles.errorMessage}>{errors.evModel}</div>}
      </div>
      <div className={styles.formControl}>
        <label>Variant (leave blank if unsure)</label>
        <input
          type="text"
          name="evModelVariant"
          value={values.evModelVariant}
          onChange={handleChange}
          placeholder="e.g. Performance or Long Range"
        />
        {errors.evModelVariant && <div className={styles.errorMessage}>{errors.evModelVariant}</div>}
      </div>
      <div className={styles.formControl}>
        <label>Year *</label>

        <MySelect
          name="evYear"
          value={values.evYear}
          onChange={handleChange}
          placeholder="Select year"
          hasSearch={false}
        >
          {years.map((year) => (
            <option key={year.toString()} value={year.toString()}>
              {year.toString()}
            </option>
          ))}
        </MySelect>
        {errors.evYear && <div className={styles.errorMessage}>{errors.evYear}</div>}
      </div>
      {audienceType === 'mo' && (
        <div className={styles.formControl}>
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              name="shareDataWithMotability"
              checked={shareDataWithMotability}
              onChange={handleChange}
            />
            <span className={styles.checkboxText}>I agree to share data with Motability Operations</span>
          </label>
          {errors.shareDataWithMotability && (
            <div className={styles.errorMessage}>{errors.shareDataWithMotability}</div>
          )}
        </div>
      )}

      <div className={styles.actions}>
        <button className={styles.action} onClick={goBack}>
          <FaArrowLeft className={styles.icon} size={24} />
          <div className={styles.label}>Back</div>
        </button>
        <button className={styles.action} onClick={validateAndProceed}>
          <div className={styles.label}>Submit</div>
          <FaArrowRight className={styles.icon} size={24} />
        </button>
      </div>
    </div>
  )
}
