import styles from "@/components/FooterContainer.module.css";
import Link from "next/link";

const FooterContainer = () => {
  return (
    <div className={styles.footer1}>
      <div className={styles.footer1Content}>
        <div className={styles.footer1Left}>
          <img className={styles.smallLogoIcon} alt="" src="/smalllogo.svg" />
        </div>
        <div className={styles.footer1Right}>
          <div className={styles.footer1Right1}>
            <div className={styles.header}>
              <Link href="/contact" className={styles.title}>Contact us</Link>
              <div className={styles.description}>
                Contact us for any questions or inquiries
              </div>
            </div>
            <div className={styles.buttons}>
              <a
                href="mailto:hello@clearwatt.co.uk"
                target="_blank"
                className={styles.button}>
                hello@clearwatt.co.uk
              </a>
              <div className={styles.button}>
                <p className={styles.westminsterBridgeRd}>
                  County Hall Belvedere Road, <br />
                  London SE1 7PB
                </p>
              </div>
            </div>
          </div>
          <div className={styles.footer1Right2}>
            <div className={styles.header}>
              <div className={styles.title}>Follow us</div>
              <div className={styles.description1}>
                Follow us to stay connected with our community
              </div>
            </div>
            <div className={styles.buttons1}>
              <a
                href="https://www.linkedin.com/company/clearwatt"
                target="_blank"
                className={styles.button3}>
                LinkedIn
              </a>
              {/* <div className={styles.button3} />
              <div className={styles.button3} />
              <div className={styles.button3} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.lineContent}>
        <div className={styles.line} />
      </div>
    </div>
  );
};

export default FooterContainer;
