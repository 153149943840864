import { useState, useEffect, useCallback } from 'react';

const useTypewriterOnScroll = (refs, messages, styles, delays = 0) => {
  const [animatedIndices, setAnimatedIndices] = useState(new Set());

  const typewriter = useCallback(
    (refIndex) => {
      if (animatedIndices.has(refIndex)) return;

      setAnimatedIndices((prev) => new Set([...prev, refIndex]));

      const node = refs[refIndex].current;
      const text = messages[refIndex];
      const delay = Array.isArray(delays) ? delays[refIndex] : delays;
      let i = 0;

      function type() {
        if (i < text.length) {
          node.textContent += text.charAt(i);
          i++;
          setTimeout(type, 23);
        } else {
          node.classList.remove(styles.caret);
          node.classList.remove(styles['initial-text']);
          node.classList.add(styles['final-text']);
        }
      }

      setTimeout(() => {
        node.textContent = '';
        node.classList.add(styles.caret);
        node.classList.add(styles['final-text']);
        type();
      }, delay);
    },
    [refs, messages, styles, animatedIndices, delays]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const refIndex = refs.findIndex((ref) => ref.current === entry.target);
          if (entry.isIntersecting && refIndex !== -1 && !animatedIndices.has(refIndex)) {
            typewriter(refIndex);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0, //0.2
      }
    );

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [refs, typewriter, animatedIndices]);

  return { typewriter };
};

export default useTypewriterOnScroll;
