import {useState, Children, useEffect, useRef} from 'react';
import styles from "@/components/ui/selects/MySelect.module.css"
import { FaSort } from 'react-icons/fa';


const MySelect = ({name, value, onChange, children, placeholder = 'Select...', hasSearch = false}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);

  const selectedChild = Children.toArray(children).find((child) => child.props.value === value);
  const selectedDisplay = selectedChild ? selectedChild.props.children : null;

  const filteredChildren = Children.toArray(children).filter((child) => {
    const {value, children, searchKey} = child.props;
    const searchKeyActual = searchKey || children;
    if (typeof searchKeyActual === 'string') {
      return !searchTerm || searchKeyActual.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  }).map((child) => {
    const {value, children} = child.props;
    return (
      <span key={value} value={value}>
        {children}
      </span>
    );
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (child) => {
    onChange({target: {name, value: child.props.value}});
    setSearchTerm(""); // Clear the search term
    setShowDropdown(false);
  };

  return (
    <div ref={wrapperRef} style={{position: 'relative'}}>
      <button
        className={styles.button}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedDisplay || placeholder}
        <FaSort />
      </button>
      {showDropdown && (
        <div className={styles.content}>
          {hasSearch && (
            <input
              className={styles.searchField}
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search..."
            />
          )}
          <ul className={styles.list}>
            {filteredChildren.length ? (
              filteredChildren.map((child) => (
                <li
                  className={styles.option}
                  key={child.props.value}
                  onClick={() => handleOptionClick(child)}
                  role="option"
                >
                  {child}
                </li>
              ))
            ) : (
              <li className={styles.option} role="option">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MySelect;
