import { useEffect, useState, useCallback } from 'react';

const useRevealOnScroll = (refs, delays = 0) => {
  const [revealedIndices, setRevealedIndices] = useState(new Set());

  const reveal = useCallback(
    (refIndex) => {
      if (revealedIndices.has(refIndex)) return;

      setRevealedIndices((prev) => new Set([...prev, refIndex]));
    },
    [revealedIndices]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const refIndex = refs.findIndex((ref) => ref.current === entry.target);
          if (entry.isIntersecting && refIndex !== -1 && !revealedIndices.has(refIndex)) {
            const delay = Array.isArray(delays) ? delays[refIndex] : delays;
            setTimeout(() => {
              reveal(refIndex);
            }, delay);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [refs, reveal, revealedIndices, delays]);

  return { revealedIndices };
};

export default useRevealOnScroll;
